import { Component} from '@angular/core';
import {LanguageService} from "../../service/language.service";

@Component({
  selector: 'app-what-we-do',
  templateUrl: './what-we-do.component.html',
  styleUrls: ['./what-we-do.component.css']
})
export class WhatWeDoComponent{

  language: number;

  constructor(private languageService: LanguageService) {
    this.language = languageService.getCurrentLanguage();
  }
}
