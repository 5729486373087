import {Injectable} from "@angular/core";
import {Subject} from "rxjs";

@Injectable({providedIn: 'root'})
export class LanguageService {
  public languageObs: Subject<number> = new Subject<number>();

  public language = 0;

  constructor() {
    this.initialize();
  }

  private initialize() {
    const lang = localStorage.getItem('lang');
    if (!lang) {
      localStorage.setItem('lang', 'UA');
    } else {
      this.initializeLanguage(lang);
    }
  }

  public switchLanguage(lang: number) {
    switch (lang) {
      case 0:
        localStorage.setItem('lang', 'UA');
        this.language = 0;
        break;
      case 1:
        localStorage.setItem('lang', 'EN');
        this.language = 1;
        break;
      case 2:
        this.language = 2;
        localStorage.setItem('lang', 'RU');
    }
    this.languageObs.next(this.language);
  }

  public initializeLanguage(lang: string) {
    switch (lang) {
      case 'UA':
        this.language = 0;
        break;
      case 'EN':
        this.language = 1;
        break;
      case 'RU':
        this.language = 2;
    }
  }

  public getCurrentLanguage(): number {
    this.initialize();
    return this.language;
  }
}
