import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NavigationComponent} from "./navigation/navigation.component";
import {MainPageComponent} from "./components/main-page/main-page.component";
import {ProjectsComponent} from "./components/projects/projects.component";
import {WhatWeDoComponent} from "./components/what-we-do/what-we-do.component";
import {StagesComponent} from "./components/stages/stages.component";
import {OrderComponent} from "./components/order/order.component";
import {ContactsComponent} from "./components/contacts/contacts.component";
import {FeedbackFormComponent} from "./feedback-form/feedback-form.component";

const routes: Routes = [
  {path: "", component: NavigationComponent},
  {path: "home", component: MainPageComponent},
  {path: "projects", component : ProjectsComponent},
  {path: "what-we-do", component : WhatWeDoComponent},
  {path: "stages", component : StagesComponent},
  {path: "order", component : OrderComponent},
  {path: "contacts", component : ContactsComponent},
  {path: "feedback", component : FeedbackFormComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
