import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {LanguageService} from "../service/language.service";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit, OnDestroy {

  languageSub: Subscription;

  pagesUA = ['Головна', 'Що ми робимо', 'Проекти', 'Етапи проектування', 'Як замовити', 'Контакти', 'Зворотній зв\`язок'];
  pagesEN = ['Main', 'What we do', 'Projects', 'Steps of projection', 'How to order', 'Contacts', 'Feedback'];
  pagesRU = ['Главная', 'Что мы делаем', 'Проекты', 'Етапы проектирования', 'Как заказать', 'Контакты', 'Обратная связь'];

  paths = ['/home', '/what-we-do', '/projects', '/stages', '/order', '/contacts', '/feedback'];

  lastActivePage;

  currentUaPage;
  currentEnPage;
  currentRuPage;

  language: number;
  languagesString = ['Українська', 'English', 'Русский'];
  languagesIcons = ['/assets/countries/ua-icon.png', '/assets/countries/us-icon.png', '/assets/countries/ru-icon.png'];
  currentLanguageIcon;
  currentLanguageString;
  activeLanguage = [false, false, false];

  resolutionOk = window.innerWidth > 1265;

  constructor(private router: Router, private languageService: LanguageService) {
    this.language = languageService.getCurrentLanguage();
    this.languageSub = this.languageService.languageObs.subscribe(value => {
      this.language = value;
    });
    this.activeLanguage[this.language] = true;
    this.currentLanguageIcon = this.languagesIcons[this.language];
    this.currentLanguageString = this.languagesString[this.language];

    const path = window.location.pathname;

    if (path === '/') {
      this.lastActivePage = 0;
      this.router.navigate(['/home']);
    } else if (path === '/feedback') {
      this.router.navigate(['/feedback']);
    }

    for (let i = 0; i < this.paths.length; i++) {
      if (this.paths[i] === path) {
        this.lastActivePage = i;
        this.currentUaPage = this.pagesUA[i];
        this.currentEnPage = this.pagesEN[i];
        this.currentRuPage = this.pagesRU[i];
        break;
      }
    }
  }

  ngOnInit(): void {
  }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.resolutionOk = event.target.innerWidth > 1265;
  }


  changeLanguage(index:number) {
    this.languageService.switchLanguage(index);
    window.location.reload();
  }

  setCurrentPage(i: number) {
    this.lastActivePage = i;
    switch (this.language) {
      case 0:
        this.currentUaPage = this.pagesUA[this.lastActivePage];
        break;
      case 1:
        this.currentEnPage = this.pagesEN[this.lastActivePage];
        break;
      case 2:
        this.currentRuPage = this.pagesRU[this.lastActivePage];
        break;
    }
  }

  ngOnDestroy() {
    this.languageSub.unsubscribe();
  }
}
