import {Component} from '@angular/core';
import {LanguageService} from "./service/language.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'time-electro';
  language: number;

  constructor(private router: Router, private languageService: LanguageService) {
    this.language = languageService.getCurrentLanguage();
  }

  returnMessage():string {
    switch (this.language) {
      case 0:
        return 'Зворотній зв\'язок';
      case 1:
        return 'Feedback';
      case 2:
        return 'Обратная связь';
    }
    return '';
  }

  feedbackRoute() {
    const path = window.location.pathname;
    if (path === '/feedback') {
      window.location.reload();
    }
    this.router.navigate(['/feedback']);
  }
}
