import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {MainPageComponent} from './components/main-page/main-page.component';
import {ContactsComponent} from './components/contacts/contacts.component';
import {ImageDialog, ProjectsComponent} from './components/projects/projects.component';
import {WhatWeDoComponent} from './components/what-we-do/what-we-do.component';
import {StagesComponent} from './components/stages/stages.component';
import {OrderComponent} from './components/order/order.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavigationComponent} from './navigation/navigation.component';
import {AppRoutingModule} from "./app-routing.module";
import {MaterialModule} from "./material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatRippleModule} from "@angular/material/core";
import {FeedbackFormComponent} from './feedback-form/feedback-form.component';
import {HttpClientModule} from "@angular/common/http";
import {DropdownDirective} from "./shared/dropdown.directive";

@NgModule({
  declarations: [
    AppComponent,
    MainPageComponent,
    ContactsComponent,
    ProjectsComponent,
    WhatWeDoComponent,
    StagesComponent,
    OrderComponent,
    NavigationComponent,
    ImageDialog,
    FeedbackFormComponent,
    DropdownDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatRippleModule,
    MatSidenavModule,
    FlexLayoutModule,
    HttpClientModule
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
