import {Component} from '@angular/core';
import {LanguageService} from "../../service/language.service";

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html',
  styleUrls: ['./main-page.component.css']
})
export class MainPageComponent {

  language: number;


  constructor(private languageService: LanguageService) {
    this.language = languageService.getCurrentLanguage();
  }
}
