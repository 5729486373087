import { Component} from '@angular/core';
import {LanguageService} from "../../service/language.service";

@Component({
  selector: 'app-steps',
  templateUrl: './stages.component.html',
  styleUrls: ['./stages.component.css']
})
export class StagesComponent {

  language: number;

  constructor(private languageService: LanguageService) {
    this.language = languageService.getCurrentLanguage();
  }
}
