import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, FormArray, Validators, AbstractControl} from '@angular/forms';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {LanguageService} from "../service/language.service";

@Component({
  selector: 'app-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.css']
})
export class FeedbackFormComponent implements OnInit {

  language: number;

  feedbackForm!: FormGroup;

  customerNameChanged: "" | boolean = false;
  customerNameControl!: AbstractControl | null;

  customerEmailChanged: "" | boolean = false;
  customerEmailControl!: AbstractControl | null;

  customerPhoneChanged: "" | boolean = false;
  customerPhoneControl!: AbstractControl | null;

  checkDdos: any;

  clicked:boolean = false;
  checkDdosControl!: AbstractControl | null;


  constructor(private formBuilder: FormBuilder,
              private http:HttpClient,
              private languageService: LanguageService) {
    this.language = languageService.getCurrentLanguage();
    this.buildFeedbackForm();
  }

  ngOnInit() {
    this.checkDdos = this.returnName();
  }

  buildFeedbackForm() {
    this.feedbackForm = this.formBuilder.group({
          customerName: this.formBuilder.control(null, [Validators.required, Validators.minLength(6)]), // same as above but expects null by default
          customerEmail: this.formBuilder.control(null, [Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]), // same as above but expects null by default
          customerPhone: this.formBuilder.control(null, [Validators.required, Validators.minLength(13),
            Validators.maxLength(13), Validators.pattern('^\\+?\\d*$')]), // same as above but expects null by default
          suggestions: this.formBuilder.control(null, [Validators.required, Validators.minLength(10)]),
          checkDdosControl: this.formBuilder.control(null, [Validators.required])
        },
        {
          validators: this.checkDdosMethod('checkDdosControl')
        });

    this.customerNameControl = this.feedbackForm.get('customerName');

    this.customerEmailControl = this.feedbackForm.get('customerEmail');

    this.customerPhoneControl = this.feedbackForm.get('customerPhone');

    this.customerNameControl?.valueChanges.subscribe((data: string) => {
      this.customerNameChanged = data && data.toUpperCase().trim() === "TESTING";
    });
  }

  private checkDdosMethod(checkDdosControl1: string) {
    return (group: FormGroup): {[key:string]: any} => {
      this.checkDdosControl = group.controls[checkDdosControl1];
      const checkDdosFromFormControl = this.checkDdosControl?.value;
      if (this.checkDdos !== this.returnName() && checkDdosFromFormControl && checkDdosFromFormControl == this.checkDdos) {
        return {};
      }
      return {error: "Капча не співпадає"};
    }

  }

  clearForm() {
    this.feedbackForm.reset();
  }

  submitFeedbackForm() {
    this.sendFeedback();
    this.clearForm();
    this.clicked = false;
    window.location.reload();
  }

  private sendFeedback() {

    const customerName = this.customerNameControl?.value;
    const customerEmail = this.customerEmailControl?.value;
    const customerPhone = this.customerPhoneControl?.value;
    const suggestions = this.feedbackForm.get('suggestions')?.value;

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    this.http.post('https://formspree.io/mwkzqgqo',
        { name: customerName, replyto: customerEmail, message: 'Телефон:' + customerPhone + '. Сообщение: ' + suggestions },
        { 'headers': headers }).subscribe(
        response => {
          console.log(response);
        }
    );
  }

  clickCheckDdos() {
    this.checkDdos = Math.floor(Math.random() * (999999 - 100000)) + 100000;
  }

  returnName() {
    switch (this.language) {
      case 0:
      case 2:
        return 'КАПЧА';
      case 1:
        return 'CAPTCHA';
    }
    return '';
  }
}
