import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {LanguageService} from "../../service/language.service";

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit{

  language: number;

  projectPhotos:string[] = [
      '/assets/projects/1.jpg',
      '/assets/projects/2.jpg',
      '/assets/projects/3.jpg',
      '/assets/projects/4.jpg',
      '/assets/projects/5.jpg',
      '/assets/projects/6.jpg',
      '/assets/projects/7.jpg',
      '/assets/projects/8.jpg',
      '/assets/projects/9.jpg',
      '/assets/projects/10.jpg',
      '/assets/projects/11.jpg',
      '/assets/projects/12.jpg',
      '/assets/projects/13.jpg',
      '/assets/projects/14.jpg',
      '/assets/projects/15.jpg',
      '/assets/projects/16.jpg',
      '/assets/projects/17.jpg',
      '/assets/projects/18.jpg',
      '/assets/projects/19.jpg',
      '/assets/projects/20.jpg',
      '/assets/projects/21.jpg',
      '/assets/projects/22.jpg',
      '/assets/projects/23.jpg',
      '/assets/projects/24.jpg',
      '/assets/projects/25.jpg',
      '/assets/projects/26.jpg',
      '/assets/projects/27.jpg',
      '/assets/projects/28.jpg',
      '/assets/projects/29.jpg',
  ];

  constructor(public dialog: MatDialog, private languageService: LanguageService) {
    this.language = languageService.getCurrentLanguage();
  }

  ngOnInit(): void {
  }

  openImage(images:string[], index:number) {
    console.log('inside open image method');
    const dialogRef = this.dialog.open(ImageDialog, {
      width: '75%',
      height: '75%',
      panelClass: 'custom-dialog-container',
      data: {
        images: images,
        index: index
      }
    });
  }

  returnMainMassage() {
    switch (this.language) {
      case 0:
        return 'Проекти';
      case 1:
        return 'Projects';
      case 2:
        return 'Проекты';
    }
    return '';
  }

  returnDescription() {
    switch (this.language) {
      case 0:
        return 'Презентуємо декілька виконаних проектів з фотозвітом';
      case 1:
        return 'We present several completed projects with a photo report';
      case 2:
        return 'Представляем несколько выполненных проектов с фотоотчетом';
    }
    return '';
  }
}

@Component({
  selector: 'image-dialog',
  templateUrl: 'image-dialog.html',
  styleUrls: ['./dialog.css']
})
export class ImageDialog implements OnInit {

  language: number;

  isDataLoaded = false;
  image!:string;
  images!:string[];
  index!:number;

  maxWidth = (window.innerHeight * 0.75 * 0.75) + 'px';

  constructor(
      public dialogRef: MatDialogRef<ImageDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any,
      private languageService: LanguageService) {
    this.language = languageService.getCurrentLanguage();
    this.images = data.images;
    this.index = data.index;
    this.image = data.images[data.index];
  }

  ngOnInit() {
    console.log('inside dialog method. image: ' + this.data.image);
  }

  clickNext() {
    if (this.index !== this.images.length - 1) {
      this.image = this.images[this.index + 1];
      this.index += 1;
    }
  }

  clickPrev() {
    if (this.index !== 0) {
      this.image = this.images[this.index - 1];
      this.index -= 1;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    let currentWidth = event.target.innerWidth;
    this.maxWidth = (currentWidth * 0.75 * 0.75) + 'px';
    this.dialogRef.updateSize((currentWidth * 0.75) + 'px');
  }

  returnPrevMassage() {
    switch (this.language) {
      case 0:
        return 'ПОПЕРЕДНЯ';
      case 1:
        return 'PREVIOUS';
      case 2:
        return 'ПРЕДЫДУЩАЯ';
    }
    return '';
  }

  returnNextMessage() {
    switch (this.language) {
      case 0:
        return 'НАСТУПНА';
      case 1:
        return 'NEXT';
      case 2:
        return 'СЛЕДУЮЩАЯ';
    }
    return '';
  }
}
