import { Component} from '@angular/core';
import {LanguageService} from "../../service/language.service";

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css']
})
export class OrderComponent{

  language: number;

  constructor(private languageService: LanguageService) {
    this.language = languageService.getCurrentLanguage();
  }
}
