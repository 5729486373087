import { Component} from '@angular/core';
import {LanguageService} from "../../service/language.service";

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})
export class ContactsComponent{

    language: number;
    isCopy:boolean = false;

  constructor(private languageService: LanguageService) {
    this.language = languageService.getCurrentLanguage();
  }

    copy() {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = 'timeelectrotov2021@gmail.com';
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        this.isCopy = true;
    }

    returnCopyMessage() {
        switch (this.language) {
            case 0:
                return 'СКОПІЮВАТИ';
            case 1:
                return 'COPY';
            case 2:
                return 'СКОПИРОВАТЬ';
        }
        return '';
    }
}
